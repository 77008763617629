<template>
    <div class="popUpForm" v-loading="loading">
        <div class="content">
            <el-tabs v-model="activeName">
                <el-tab-pane name="form">
                    <span slot="label"><i class="iconfont iconbiaodan"></i>表单</span>
                    <formPage :form-data="code"></formPage>
                </el-tab-pane>
            </el-tabs>
        </div>
        <footer>
            <el-button
                type="primary"
                class="save"
                @click="save(1)"
                :loading="btnLoading"
                v-if="threeBtn && isReadOnly == false"
            >
                {{ threeBtn }}
            </el-button>
            <el-button
                class="save"
                type="primary"
                :loading="saveBtnLoading"
                @click="save(2)"
                v-if="isReadOnly == false"
            >
                保存
            </el-button>
            <el-button class="Close" @click="Close">
                关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
import store from '@/store';
import formPage from '@/components/form/formPage';

export default {
    components: {
        formPage,
    },
    props: {
        code: {
            type: [Array, Object, String],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            // 加载中状态
            loading: false,
            // 默认展开tab页
            activeName: 'form',
            // 附件状态
            isDisabled: true,
            // 当前表单ID
            formId: '',
            // 表单是否只读状态,判断保存按钮是否显示
            isReadOnly: false,
            // 当前表单唯一标识
            thisFormOnly: '',
            // 是否显示第三按钮
            threeBtn: '',
            // 按钮点击
            statusSave: 0,
            btnStatus: '',
            // 保存按钮状态
            saveBtnLoading: false,
            btnLoading: false,
        };
    },
    created() {
        this.threeBtn = this.code.ThreeBtnDescribe;
        this.btnStatus = this.code.BtnStatus;
        if (this.code.FormUrl) {
            // 表单是否只读状态,判断保存按钮是否显示
            if (this.$queryString(this.code.FormUrl, 'IsView')) {
                this.isReadOnly = true;
            } else {
                this.isReadOnly = false;
            }
            // 当前表单唯一标识赋值
            this.thisFormOnly = this.$queryString(this.code.FormUrl, 'FormCode');
        }
        if (this.code.FormKeyValue) {
            this.isDisabled = false;
            this.formId = this.code.FormKeyValue;
            // this.gitAnnexData(this.code.FormKeyValue);
        }
    },
    methods: {
        // 保存表单
        save(status) {
            if (status === 1) {
                this.btnLoading = true;
            } else {
                this.saveBtnLoading = true;
            }
            this.statusSave = status;
            const iframe = window.document.getElementById('frameId' + this.thisFormOnly);
            iframe.contentWindow.JyFormSaveFormData(this.JTFlowAfterFormSaveSuccess, this.errorCallback);
        },
        // 保存表单成功回调
        // eslint-disable-next-line complexity
        async JTFlowAfterFormSaveSuccess(PKValues) {
            this.btnLoading = false;
            this.saveBtnLoading = false;
            this.isDisabled = false;
            this.formId = PKValues.PKValues;
            const iframe = window.document.getElementById('frameId' + this.thisFormOnly);
            if (this.statusSave === 1) {
                if (this.btnStatus === 'he') {
                // 运输单
                    const idList = [PKValues.PKValues];
                    const status = this.threeBtn === '核算完成' ? 4 : 2;
                    const obj = {
                        ids: idList,
                        status: status,
                    };
                    this.$axios
                        .put('/interfaceApi/sale/accounts/delivery_receipt_batch_check', obj)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'gbd') {
                // 过磅单
                    const idList = [PKValues.PKValues];
                    const status = this.threeBtn === '核算' ? 4 : 2;
                    const obj = {
                        ids: idList,
                        status: status,
                    };
                    this.$axios
                        .put('/interfaceApi/procurement/accounts/balance_batch_check', obj)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'htjs') {
                // 合同结算
                    this.$axios
                        .post(`/interfaceApi/sale/accounts/settlement/complete_reconcile/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'skjl') {
                // 收款记录
                    this.$axios
                        .post(`/interfaceApi/sale/accounts/proceeds/complete/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'yljs') {
                // 原料结算
                    this.$axios
                        .post(`/interfaceApi/procurement/accounts/settlement/complete_reconcile/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'yfjs') {
                // 运费结算
                    this.$axios
                        .post(`/interfaceApi/procurement/accounts/settlement/complete_reconcile_yf/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'fkjl') {
                // 付款记录
                    this.$axios
                        .post(`/interfaceApi/procurement/accounts/payment/complete/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'instock') {
                    iframe.contentWindow.instock(this);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                } else if (this.btnStatus === 'outstock') {
                    iframe.contentWindow.outstock(this);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                } else if (this.btnStatus === 'transferIn') {
                    iframe.contentWindow.transferIn(this);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                } else if (this.btnStatus === 'transferOut') {
                    iframe.contentWindow.transferOut(this);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                } else if (this.btnStatus === 'transferConfirm') {
                    iframe.contentWindow.transferConfirm(this);
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                } else if (this.btnStatus === 'tkjl') {
                    // 退款记录（废弃）
                    iframe.contentWindow.complete();
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                } else if (this.btnStatus === 'submitProceeds') {
                    // 收款提交
                    iframe.contentWindow.submitProceeds();
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                } else if (this.btnStatus === 'confirmProceeds') {
                    // 收款完成
                    iframe.contentWindow.confirmProceeds();
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                } else if (this.btnStatus === 'backProceeds') {
                    // 收款退回
                    this.$axios
                        .post(`/interfaceApi/sale/accounts/retail/confirm/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'submitRefund') {
                    // 退款提交
                    await iframe.contentWindow.submitRefund();
                } else if (this.btnStatus === 'confirmRefund') {
                    // 退款完成
                    await iframe.contentWindow.confirmRefund();
                } else if (this.btnStatus === 'submitRetail') {
                    // 零售补方退款提交
                    iframe.contentWindow.submitRetail(this.$parent.hackReset);
                } else if (this.btnStatus === 'confirmRetail') {
                    // 零售补方退款确认
                    this.$axios
                        .post(`/interfaceApi/sale/accounts/retail/confirm/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'submitStatement') {
                    // 提交对账单
                    this.$axios
                        .post(`/interfaceApi/sale/accounts/statement/submit_statement/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'confirmStatement') {
                    // 确认对账单
                    this.$axios
                        .post(`/interfaceApi/sale/accounts/statement/confirm_statement/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'confirmDiffStatement') {
                    // 确认对账单调差
                    this.$axios
                        .post(`/interfaceApi/sale/accounts/difference_statement/complete/${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'ysdyfhs') {
                    const idList = [PKValues.PKValues];
                    this.$axios
                        .put('/interfaceApi/sale/accounts/batch_calculate_delivery_yf', idList)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'wlyfjs') {
                    this.$axios
                        .post('/interfaceApi/sale/accounts/settlement/transport/complete/' + PKValues.PKValues)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else if (this.btnStatus === 'submitInfoToOrder') { // 销售管理-自用供料提交
                    this.$axios
                        .post(`/interfaceApi/sale/selfuse_concrete/submit_to_order?id=${PKValues.PKValues}`)
                        .then(() => {
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                }
                this.$parent.hide();
            } else {
                store.state.currentOpenList.forEach(item => {
                    item.tableObj.updateTable();
                });
            }
        },
        // 保存失败回调
        errorCallback() {
            this.btnLoading = false;
            this.saveBtnLoading = false;
        },
        // 刷新列表
        updateTable() {
            store.state.currentOpenList.forEach(item => {
                item.tableObj.updateTable();
            });
        },
        // 取消添加关闭弹出层
        Close() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus"></style>
